import { Injectable } from '@angular/core';
import { DeviceService } from "./device.service";
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token, Channel } from "@capacitor/push-notifications";
import { ApiService } from './api.service';
import { ChatService } from './chat.service';
import { EventsService } from './events.service';
import { AlertService } from './alert.service';

// TODO: checkPermissions

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  private token = "";

  constructor(
    private deviceService: DeviceService,
    private apiService: ApiService,
    private eventsService: EventsService,
    private chatService: ChatService,
    private alertService: AlertService
  ) { }

  // success and error-handling for register in one function
  private async registered(token, error) {
    if (error != null) {
      console.log("PUSH", "register failed", error)
      return {
        "status": "generic_app_error",
        "status_text": "failed to register for push-notifications"
      }
    }

    PushNotifications.createChannel({
      id: 'general',
      name: 'Wichtige Benachrichtigungen',
      description: '',
      sound: 'ding.wav',
      importance: 4,
      visibility: 1,
      vibration: true,
    });

    let result = await this.apiService.setPushEndpoint(token)
    result["endpoint"] = token

    if (result["status"] != "ok") {
      console.log("PUSH", "failed to send endpoint", result)
    } else {
      console.log("PUSH", "endpoint sent", result)
      this.token = token
    }


    return result
  }


  public async register() {
    this.token = "";

    // if necessary: ask for permission
    // before calling the permission-dialog, show an info-screen
    // and explain what this request is about
    try {
      let permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === 'prompt') {
        await this.alertService.presentAlert("Damit du keine Chatnachrichten verpasst, möchten wir dir Benachrichtigungen schicken. Du wirst im nächsten Schritt gefragt, ob du das gestatten möchtest.");
        permStatus = await PushNotifications.requestPermissions();
      }
    
      if (permStatus.receive !== 'granted') {
        return {
          "status": "no_permission"
        }
      }    
    } catch (error) {
      return {
        "status": "generic_app_error",
        "status_text": "failed to ask for permissions (push)",
        "error": error
      }
    }


    return await new Promise((resolve, reject) => {
      PushNotifications.removeAllListeners()

      PushNotifications.addListener(
        'registration',
        async (token: Token) => {
          resolve(await this.registered(token.value, null))
        }
      );

      PushNotifications.addListener(
        'registrationError',
        async (error: any) => {
          resolve(await this.registered(null, error))
        }
      );

      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => { this.pushReceived(notification) });
      PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => { this.pushAction(action) });

      PushNotifications.register()
    });

  }

  public async unregister() {
    this.token = "";

    let result = await this.apiService.unsetPushEndpoint()

    if (result["status"] != "ok") {
      console.log("PUSH", "failed to remove endpoint", result)
    } else {
      console.log("PUSH", "endpoint removed", result)
    }

    return result
  }



  private async pushReceived(notification: PushNotificationSchema) {
    console.log("PUSH", "push received", this.deviceService.paused, notification)
    // if push was received while app is in front:
    // connect to chatserver to receive new messages
    if (!this.deviceService.paused) {
      this.chatService.interact()
    }
  }

  private async pushAction(action: ActionPerformed) {
    console.log("PUSH", "push action performed", action)
    if (action.actionId == "tap") {
      this.eventsService.publishNotificationActionEvent(action.notification.data);
    }
  }

}

