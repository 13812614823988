import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { EventsService } from './events.service';
import { DeviceService } from './device.service';
import { AlertService } from './alert.service';
import { ChatService } from './chat.service';

const PUBLIC_KEY = "BOYHQyxxj2hm9FcV1bxu_mrWZoCQizG_v0vB0V5Mlt1OgyYUqo0MVTBOtrZ01FmR-j9tqKz4HH2OoBJUdAFO34k";

// TODO: permissionState

@Injectable({
  providedIn: 'root'
})
export class WebpushService {

  public token = ""
  private servicerWorkerRegistration = null

  constructor(
    private deviceService: DeviceService,
    private apiService: ApiService,
    private eventsService: EventsService,
    private alertService: AlertService,
    private chatService: ChatService
  ) { }

  // this is basically urlBase64ToUint8Array() with a constant 
  private getApplicationServerKey() {

    const padding = '='.repeat((4 - PUBLIC_KEY.length % 4) % 4);
    const base64 = (PUBLIC_KEY + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }


  private async requestPermission() {
    try {
      let permission = await Notification.requestPermission()
      console.log("PUSH", "requestPermission returned ", permission)
      return permission == "granted"
    } catch (error) {
      console.log("PUSH", "requestPermission failed", error)
    }
    return false
  }




  public async register() {

    // is webpush available!?
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      return {
        "status": "not_supported" // is that the correct error!?
      }
    }

    // ask for permission
    try {
      if (Notification.permission != "granted") {
        if (Notification.permission != "denied") {
          await this.alertService.presentAlert("Damit du keine Chatnachrichten verpasst, möchten wir dir Benachrichtigungen schicken. Du wirst im nächsten Schritt gefragt, ob du das gestatten möchtest.");
        }
        let permStatus = await Notification.requestPermission()
        if (permStatus !== 'granted') {
          return {
            "status": "no_permission"
          }
        }      
      }
    } catch (error) {
      return {
        "status": "generic_app_error",
        "status_text": "failed to ask for permissions (push)",
        "error": error
      }
    }

    // register service-worker

    let registration = null;
    try {
      registration = await navigator.serviceWorker.register('/assets/js/serviceworker.js')
    } catch (error) {
      return {
        "status": "generic_app_error",
        "status_text": "failed to register service-worker"
      }
    }

    navigator.serviceWorker.addEventListener('message',(event) => {
      console.log('Received a message from service worker: ', event);

      // belt and suspenders:
      if (event == null) return;
      if (event.data == null) return;
      if (event.data.message == null) return;

      if (event.data.message = "webpush received") {
        this.chatService.interact()
      }

      if (event.data.message = "webpush notification clicked") {
        this.eventsService.publishNotificationActionEvent(event.data.data.data); // horrible
      }

    });    

    this.servicerWorkerRegistration = registration

    let subscription = await registration.pushManager.getSubscription();
    if (subscription != null) {
      console.log("PUSH","subscription already exists",subscription)
      return {
        "status": "ok"
      }
    }

    try {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.getApplicationServerKey()
      });
      console.log("PUSH","subscription created",subscription)

    } catch (error) {
      return {
        "status": "generic_app_error",
        "status_text": "failed to subscribe to push-events"
      }
    }

    let token = JSON.stringify(subscription);
    let result = await this.apiService.setPushEndpoint(token)
    result["endpoint"] = token

    if (result["status"] != "ok") {
      console.log("PUSH", "failed to send endpoint", result)
    } else {
      console.log("PUSH", "endpoint sent", result)
      this.token = token
    }

    return result
  }

  public async unregister() {

    this.token = "";

    if (this.servicerWorkerRegistration != null) {
      let subscription = this.servicerWorkerRegistration.pushManager.getSubscription();
      if (subscription != null) {
        try {
          await subscription.unsubscribe()
        } catch (error) {
          console.log("PUSH", "failed to unsubscribe", error)
        }
      }
    }

    let result = await this.apiService.unsetPushEndpoint()
    if (result["status"] != "ok") {
      console.log("PUSH", "failed to remove endpoint", result)
    } else {
      console.log("PUSH", "endpoint removed", result)
    }

    return result
  }



}
