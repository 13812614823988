import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { DeviceService } from "./device.service";
import { StorageService } from "./storage.service";
import { NetworkService } from "./network.service";
import { EventsService } from "./events.service";
import { lastValueFrom } from "rxjs";

// TODO: timeouts!

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private serverUrl = 'https://api.desade.de/v1/';

    public recovery_user_handle: string;
    public recovery_accesstoken: string;

    public bearertoken: string;
    public bearertoken_expires: string;

    public pretendServerError: boolean = false;


    constructor(
        private http: HttpClient,
        private deviceService: DeviceService,
        private storageService: StorageService,
        private networkService: NetworkService,
        private eventsService: EventsService
    ) { }

    private async getHeaders(withAuth: boolean) {
        let systemLang = await this.deviceService.getInformation();
        systemLang = systemLang['systemLanguage']['value'];

        let headers = {
            'Content-Type': 'application/json',
            'Accept-Language': systemLang
        }

        if (withAuth) {
            if (this.bearertoken == "") {
                throw new Error('bearertoken missing');
            }
            headers["Authorization"] = "Bearer " + this.bearertoken;
        }

        return headers;
    }

    private async getAppAndDeviceInfo() {
        const device = await this.deviceService.getInformation();
        const clientInfo = await this.deviceService.clientInfo();

        return {
            "appid": device.uuid,
            "client_info": clientInfo,
        }
    }

    private async isOnline() {
        try {
            return await this.networkService.deviceConnected();
        } catch (e) {
            console.log("APISERVICE","isOnline exception",e)
            return false; // TODO really!? exception here means the networkService cannot respond, not necessarily we're offline
        }
    }


    public async requestAuth(method: string, url: string, payload: any = null) {
        console.log("HTTP","AUTH",method,url,payload);
        return this.http_request_ex(method,url,payload,true,false);
    }

    public async requestAnon(method: string, url: string, payload: any = null) {
        console.log("HTTP","ANON",method,url,payload);
        return this.http_request_ex(method,url,payload,false,false);
    }

    public async http_request_ex(method: string, url: string, payload: any,with_auth: boolean, is_retry: boolean = false): Promise<object> {

        if ((method == "get") && (payload != null)) {
            console.log("HTTP","WARNING: PAYLOAD ON HTTP-GET")
        }


        // dont even try when you're offline...
        let isonline = await this.isOnline();
        if (!isonline) {
            return {
                "status": "offline"
            };
        }

        // if this is a request that needs authorization and we dont have it:
        // exit with error
        if (with_auth && (this.bearertoken == "")) {
            return {
                "status": "no_session"
            };
        }


        // perform the request
        let result = null;
        try {
            if (this.pretendServerError) {
                throw new Error("fake server error")
            }

            let headers = await this.getHeaders(with_auth);
            let response;
            if (method === 'post') {
                response = this.http.post(this.serverUrl + url, payload, { headers: headers });
            } else if (method === 'get') {
                response = this.http.get(this.serverUrl + url, { headers: headers });
            } else if (method === 'delete') {
                response = this.http.delete(this.serverUrl + url, { headers: headers });
            } else {
                return {
                    "status": "not_supported_by_apiservice"
                }
            }

            if (response == null) {
                console.log("response is null");
            }
            result = await lastValueFrom(response);

        } catch (e) {
            // can happen. main cause is timeouts
            console.log("APISERVICE","ERROR",e);
        }

        // handle aborts by http-module
        if (result == null) {
            // this is: timeouts and 500's
            // TODO: distinguish between the two, have a separate error
            result = {};
            result["original_status"] = "internal_abort";
            result["status"] = "internal_abort";
            return result;
        }

        // if everything is ok, just return the result
        if (result["status"] == "ok") {
            return result;
        }

        // if this is a session-error, try to recover the session
        if ((result["status"] == "must_be_signed_on") && (!is_retry)) {
            console.log("HTTP","RECOVER",method,url,payload);
            let recovery = await this.recoverSession();
            if (recovery == "ok") {
                // inform other services about new session-data
                this.eventsService.publishSessionEvent("recover")
                return this.http_request_ex(method,url,payload,with_auth,true);
            } 
            this.eventsService.publishSessionEvent("lost");
        }
        return result;
    }


    /*
    This function needs to request through the normal http.post and not
    our own http_request function. otherwise it will end up in a loop
     */
    public async refreshBearerToken() {
        let isonline = await this.isOnline();
        if (!isonline) {
            return {
                "status": "offline"
            };
        }

        let headers;
        try {
            headers = await this.getHeaders(true);
        } catch (error) {
            return {
                "status": "no_session"
            };
        }
        
        const result$ = this.http.post(this.serverUrl + 'bearertoken/refresh', '', { headers: headers });
        const result = await lastValueFrom(result$);

        if (result['status'] == 'ok') {
            this.bearertoken = result["bearertoken"],
            this.bearertoken_expires = result["bearertoken_expires"]
        }
        return result;
    }

    public async recoverSession() {
        console.log("API","recoverSession called")
        // first, try to refresh the bearertoken
        const result = await this.refreshBearerToken();

        // if that worked, we have a new, valid session
        if (result['status'] == 'ok') {
            console.log("API","recoverSession: refreshbearertoken succeeded")
            return "ok";
        }

        // if it didn't, try to signin again
        if (result['status'] == 'must_be_signed_on') {
            console.log("API","recoverSession: must sign in")
            let signinresult = await this.signOn(this.recovery_user_handle,this.recovery_accesstoken)

            if (signinresult["status"] == "ok") {
                console.log("API","recoverSession: sign in succeeded")
                this.bearertoken = signinresult["bearertoken"],
                this.bearertoken_expires = signinresult["bearertoken_expires"]
            } else {
                console.log("API","recoverSession: sign in failed with status " + signinresult["status"])
            }

            return signinresult["status"]
        } 

        // all other status-values are errors
        console.log("API","recoverSession: refreshbearertoken failed with status " + result["status"])
        return result["status"];
    }



    // TODO: rename to signin
    public async signOn(userHandle: string, accesstoken: string) {
        if ((userHandle == "") || (accesstoken == "")) {
          return {
            "status": "no_signin_data"
          };
        }
    
        let payload = await this.getAppAndDeviceInfo();
        payload['user_handle'] = userHandle;
        payload['accesstoken'] = accesstoken;
        return await this.requestAnon('post', 'signon', payload);
      }
    
    public async signOff() {
        if (this.bearertoken != "") {
            let postData = await this.getAppAndDeviceInfo();
            this.requestAuth('post', 'signoff', postData);
        }
        this.eventsService.publishSessionEvent("signoff");
    }
    

    private async sendInternalError() {
        let postData = await this.getAppAndDeviceInfo();
        //postData['log'] = await this.storageService.showDB()
        await this.requestAnon('post', 'internal', postData);
    }

    public async register(phoneNumber: string, username: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['phonenumber'] = phoneNumber;
        postData['screenname'] = username;

        return await this.requestAnon('post', 'register', postData);
    }

    public async requestVerificationCode(verificationToken: string) {
        let postData = await this.getAppAndDeviceInfo();
        postData['verificationtoken'] = verificationToken;
        return this.requestAnon('post', 'requestverification', postData);
    }

    public async confirmVerificationCode(verificationToken,code: string) {
        let postData = await this.getAppAndDeviceInfo();
        postData['verificationtoken'] = verificationToken;
        postData['code'] = code;
        return await this.requestAnon('post', 'confirmverification', postData);
    }

    public async getProfiles(handle_list) {
        if (handle_list == null) return [];
        if (!Array.isArray(handle_list)) handle_list = [handle_list];

        let postData = await this.getAppAndDeviceInfo();
        postData['handles'] = handle_list;
        return await this.requestAuth('post', 'profiles/select', postData);
    }

    public async getProfileData(fromPage): Promise<any> {
        console.log("--->DEPRECATED<---","getProfileData")
        const result = await this.requestAuth('get', 'profiles/self');
        if (result['status'] == 'ok') {
            return Promise.resolve(result);
        } else {
            return Promise.reject(null);
        }
    }

    public async saveProfile(data: object): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post', 'profiles/self', {...data,...postData});
    }

    public async synchronizeContacts(hashed_phonenumbers) {
        let postData = await this.getAppAndDeviceInfo();
        postData["contacts"] = hashed_phonenumbers
        return await this.requestAuth('post', 'profiles/self/contacts',postData);
    }

    public async synchronizeUpdatedContacts(added,removed) {
        let contacts = {}
        for (let i = 0; i < added.length; i++) {
            contacts[added[i]] = '+';
        }
        for (let i = 0; i < removed.length; i++) {
            contacts[removed[i]] = '-';
        }
        
        let postData = await this.getAppAndDeviceInfo();
        postData["contacts"] = contacts
        return await this.requestAuth('post', 'profiles/self/contacts/update',postData);
    }

    public async deleteProfilePicture(): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post', 'profiles/self/picture/delete', postData);
    }

    public async getAppConfig() {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAnon('post', 'appconfig', postData);
    }

    public async setPushEndpoint(endpoint) {
        let postData = await this.getAppAndDeviceInfo();
        postData['endpoint'] = endpoint;
        return await this.requestAuth('post', 'push/endpoint',postData);
    }

    public async unsetPushEndpoint() {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post', 'push/endpoint/remove',postData);
    }

    public async testPushEndpoint(title,body,icon,action) {
        let postData = await this.getAppAndDeviceInfo();
        postData['title'] = title;
        postData['body'] = body;
        postData['icon'] = icon;
        postData['action'] = action;

        return await this.requestAuth('post', 'push/endpoint/test', postData);
    }

    public async getGalleryImages(user_handle: string = 'self'): Promise<any> {
        const result = await this.requestAuth('get', 'profilegallery/' + user_handle);
        if (result['status'] == 'ok') {
            return Promise.resolve(result['items']);
        } else {
            return Promise.reject(new Error(result['status']));
        }
    }

    public async getGalleryImage(imageID, resolution: string = '256_256'): Promise<any> {
        const result = await this.requestAuth('get', 'profilegallery/' + imageID + '/' + resolution);

        if (result['status'] == 'ok') {
            return Promise.resolve(result);
        } else {
            return Promise.reject(new Error(result['status']));
        }
    }

    public async saveGalleryImages(imageDate): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['picture'] = imageDate.image_data;
        postData['visibility'] = imageDate.visibility;
        postData['description'] = imageDate.description;

        return await this.requestAuth('post', 'profilegallery/add', postData);
    }

    public async updateGalleryImages(imageData): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['image_id'] = imageData.image_id;
        postData['visibility'] = imageData.visibility;
        postData['description'] = imageData.description;
        if (imageData.image_data != null) postData['picture'] = imageData.image_data;

        return await this.requestAuth('post', 'profilegallery/update', postData);
    }

    public async deleteGalleryImage(imageID: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['image_id'] = imageID;

        return await this.requestAuth('post', 'profilegallery/delete', postData);
    }

    public async searchUser(searchText: string): Promise<any> {
        searchText = encodeURIComponent(searchText);
        return await this.requestAuth('get', 'profiles/search?q=' + searchText);
    }

    public async getDevices(): Promise<any> {
        return await this.requestAuth('get', 'profiles/self/clients');
    }

    public async getOnboarding(): Promise<any> {
        console.log("APISERVICE","getOnboarding");
        return await this.requestAuth('get', 'profiles/onboarding');
    }

    public async setOnboardingPrivacy(data: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['settings'] = data;
        return await this.requestAuth('post', 'profiles/onboarding/privacydone', postData);
    }

    public async setOnboardingDone(): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post', 'profiles/onboarding/done', postData);
    }

    public async resetOnboarding(): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post', 'profiles/onboarding/reset', postData);
    }

    public async insertProfileVisitor(visitedUserHandle): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['visits'] = [{
            "user_handle": visitedUserHandle,
            "date": Math.floor(Date.now()/1000.0)
        }];
        return await this.requestAuth('post', 'profiles/self/visits', postData);
    }

    public async blockedUsers() {
        return await this.requestAuth('get', 'profiles/blocked');
    }


    public async blockUser(userHandle: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['user_handle'] = userHandle;
        return await this.requestAuth('post', 'profiles/blocked/put', postData);
    }

    public async unblockUser(userHandle: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['user_handle'] = userHandle;
        return await this.requestAuth('post', 'profiles/blocked/remove', postData);
    }

    public async reportProfile(userHandle: string, reason: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['user_handle'] = userHandle;
        postData['reason'] = reason;
        const result = await this.requestAuth('post', 'profiles/report', postData);

        if (result['status'] == 'ok') {
            return Promise.resolve(true);
        } else {
            return Promise.reject(new Error(result['status']));
        }
    }


    public async friends() {
        return await this.requestAuth('get', 'profiles/friends');
    }

    public async befriendUser(userHandle: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['user_handle'] = userHandle;
        return await this.requestAuth('post', 'profiles/friends/put', postData);
    }

    public async defriendUser(userHandle: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['user_handle'] = userHandle;
        return await this.requestAuth('post', 'profiles/friends/remove', postData);
    }

    public async deviceAction(clientID: any, action: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['client_id'] = clientID;
        return await this.requestAuth('post', 'profiles/self/clients/' + action, postData);
    }

    public async sendDebugInformation(payload) {
        /*
        TODO: reactivate
        let doSend = false;

        await this.getfeefeeAppConfig();
        const config = await this.storageService.appConfig();
        if (config.send_debug_info === true) {
            doSend = true;
        } else {
            const debug = await this.storageService.myProfileData();
            if (debug.debug === true) {
                doSend = true;
            }
        }

        doSend = true;
        if (doSend) {
            await this.requestAuth('post', 'internal', payload);
        }
        */
    }


    public async getProfileImage(handle: string, resolution: string): Promise<any> {
        return await this.requestAuth('get', 'profiles/' + handle + '/picture/' + resolution);
    }

    public async getGalleryImageEx(imageid: string, resolution: string): Promise<any> {
        return await this.requestAuth('get', 'profilegallery/' + imageid + '/' + resolution);
    }

    public async uploadAttachment(buddy_handle: string, data: string, context_key: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['buddy_handle'] = buddy_handle;
        postData['context_key'] = context_key;
        postData['data'] = data;
        return await this.requestAuth('post', 'chat/attachments/upload',postData);
    }


    public async getAttachment(handle: string, resolution: string = '256_256'): Promise<any> {
        return await this.requestAuth('get', 'chat/attachments/' + handle + '/' + resolution);
    }


    public async createContactCode(): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post', 'contactcode',postData);
    }

    public async sendSMSInvite(phonenumber: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData["phonenumber"] = phonenumber
        return await this.requestAuth('post','smsinvite',postData)
    }

    public async getHasDialogs(): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post','profiles/self/hasdialogs',postData)
    }

    public async getDialogsList(): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        return await this.requestAuth('post','profiles/self/listdialogs',postData)
    }

    public async setEmail(email: string): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData['email'] = email;
        return await this.requestAuth('post', 'profiles/self/email', postData);
    }


    public async getSettings(): Promise<any> {
        return await this.requestAuth('get', 'profiles/self/settings');
    }

    public async putSettings(settings: any): Promise<any> {
        let postData = await this.getAppAndDeviceInfo();
        postData["settings"] = settings
        return await this.requestAuth('post', 'profiles/self/settings',postData);
    }

}
