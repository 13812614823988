import { Injectable } from '@angular/core';
import { LocaldbService } from "./localdb.service";
import { StorageService } from "./storage.service";
import { ApiService } from "./api.service";
import { EventsService } from './events.service';

export const LOCAL = "LOCAL";                    // only pick local data
export const LOCAL_NOSTALE = "LOCAL_NOSTALE"     // only pick local data that has not expired yet
export const REMOTE = "REMOTE";                  // only pick remote data (update local cache)
export const REMOTE_WITH_FALLBACK = "REMOTE_WITH_FALLBACK";  // try to load remote-data, fallback to cached date (stale or not) if remote fails
export const CACHED = "CACHED";                  // if local data is stale, try to load remote-data. return error if that fails
export const CACHED_STRICT = " CACHED_STRICT";    // if local data is stale, try to load remote-data. return error if that fails
export const CACHED_WITH_BACKGROUND_REFRESH = "CACHED_WITH_BACKGROUND_REFRESH";      // return local data, refresh in background

export const MAXAGE = 600000;


const CONFIG_MAXAGE_MILLIS = 1000*60*60*24;
const DEFAULT_MAX_ITEMS = 1000;

@Injectable({
  providedIn: 'root'
})
export class CachedApiService {

  public MAX_ITEMS = DEFAULT_MAX_ITEMS
  public MAXAGE_PER_ENDPOINT = {}

  public get_maxage_per_endpoint(endpoint: string): number {
    let result = MAXAGE

    let max_key_length = 0
    for (var key in this.MAXAGE_PER_ENDPOINT) {
      if (!this.MAXAGE_PER_ENDPOINT.hasOwnProperty(key)) continue

      // take the value of the longest key that endpoint startswith
      // in order to be able to specifiy a hierarchical per-endpoint
      // max-age
      let value = this.MAXAGE_PER_ENDPOINT[key]
      if (endpoint.startsWith(key) && (key.length >= max_key_length)) {
        result = value
        max_key_length = key.length
      }
    }
    return result
  }

  constructor(
    private localDB: LocaldbService,
    private api: ApiService,
    private storageService: StorageService,
    private eventsService: EventsService
  ) { }


  private cachableStatus(status) {
    return ((status == "ok") || (status == "not_found"));
  }

  private async loadLocal(request) {
    let res = await this.localDB.getAPICall(request);
    if (res == null) {
      return {
        "status": "no_local"
      }
    }

    res["_stale"] = this.isstale(res);
    return res;
  }

  private async loadRemote(request,maxage) {
    let data = await this.api.requestAuth('get',request)

    if (this.cachableStatus(data["status"])) {
      // we only store successfully retrieved calls
      // if a call returned "not_found", that is also a successful retrieval
      await this.store(request,maxage,data);
    }
    
    return data;
  }

  public async store(request,expires,data) {
    let local = await this.loadLocal(request);

    if (this.cachableStatus(local["status"])) {
      data.local_id = local.local_id;
      data.createdat = local.createdat;
    } else {
      data.createdat = Date.now();
    }

    data.request = request;
    data.expires = Date.now() + expires;

    return await this.localDB.putAPICall(data);
  }

  private isstale(data) {
    return (data["expires"] < Date.now())
  }

  private needsRefresh(data) {
    if (!this.cachableStatus(data["status"])) {
      return "no_local";
    }

    if (this.isstale(data)) {
      return "stale";
    }

    return "no";
  }

  public async get(request, strategy: string,maxage = -1) {
    if (maxage == 0) strategy = REMOTE
    if (maxage < 0) {
      maxage = this.get_maxage_per_endpoint(request)
    }

    const TAG = "APICACHE:"+strategy+":" + request;
    console.log(TAG);

    // only return remote results
    if (strategy == REMOTE) {
      return await this.loadRemote(request,maxage);
    }

    var local = null;
    local = await this.loadLocal(request);

    // only use local results, regardless of stale or not
    if (strategy == LOCAL) {
      return local;
    }


    let needsRefresh = this.needsRefresh(local);

    // return local result if not stale (except for when we prefer remote)
    if ((needsRefresh == "no") && (strategy != REMOTE_WITH_FALLBACK)) {
      console.log(TAG,"returning local result");
      return local;
    }

    // if local result is stale, do not return result
    if ((needsRefresh == "stale") && (strategy == LOCAL_NOSTALE)) {
      console.log(TAG,"cached result is stale, return stale-error");
      return {
        "status": "stale"
      }
    }

    console.log(TAG,"needs refresh: " + needsRefresh);

    // return local result but initiate background refresh
    if (strategy == CACHED_WITH_BACKGROUND_REFRESH) {
      console.log(TAG,"returned local result, background refresh");
      this.loadRemote(request,maxage);
      return local;
    }

    // load remote, return nothing (=error) if that fails
    if (strategy == CACHED_STRICT) {
      console.log(TAG,"fetching remote result, fail on errors");
      return await this.loadRemote(request,maxage);
    }

    // just try to refresh. if that fails, return local (=stale) result
    let remote = await this.loadRemote(request,maxage);
    if (remote["status"] != "ok") {

      if (remote["status"] == "banned") {
        return remote
      }
      if (remote["status"] == "denied") {
        return remote
      }


      if (local["status"] == "no_local") {
        return remote;
      }
      return local;
    }
    console.log(TAG,"returning refreshed, remote result");
    return remote;

  }


  public async flush(request) {
    return await this.localDB.removeAPICall(request);
  }

  public async cleanUp() {
    // there is no reliable way to find out total/free diskspace
    // just remove oldest (expired) entries if db is larger than x
    const appConfig = this.getAppConfig()

    let maxitems = DEFAULT_MAX_ITEMS
    if (appConfig["status"] == "ok") {
      maxitems = parseInt( appConfig["appconfig"]["apicache_maxitems"])
    }
    if (isNaN(maxitems)) {
      maxitems = DEFAULT_MAX_ITEMS
    }

    this.localDB.cleanUpAPICalls(maxitems)
  }


  public async blockedUsers(strategy: string = CACHED) {
    return await this.get('profiles/blocked',strategy);
  }

  public async friends(strategy: string = CACHED) {
    return await this.get('profiles/friends',strategy);
  }

  public async getMyProfile(strategy) {
    return await this.getProfile("self",strategy);
  }
  
  public async getProfile(user_handle,strategy) {
    return await this.get('profiles/' + user_handle,strategy);
  }


  private validateConfig(config): any {
    let error = {
      "status": "no_config",
      "_fetchedat": 0,
      "appconfig": {}
    }

    if (config == "") return error
    if (config == null) return error
    if (config["appconfig"] == null) return error

    // set defaults
    if (config["appconfig"]["typing_timeout"] == null) config["appconfig"]["typing_timeout"] = 5000;
    if (config["appconfig"]["send_debug_info"] == null) config["appconfig"]["send_debug_info"] = false;

    // fail if mandatory info is missing
    if (config["appconfig"]["socket_url"] == null) return error

    return config;
  }

  private async internalGetAppConfig(force: boolean = false) {
    // fetch and validate from local storage
    let config = this.validateConfig(await this.storageService.get('appconfig'))

    let mustreload = true;

    // (if there was a valid config,  we should reload if the config is too old
    let fetchedat = parseInt(config["_fetchedat"]);
    if (!isNaN(fetchedat)) {
      if ((Date.now()-fetchedat) < CONFIG_MAXAGE_MILLIS) {
        mustreload = false;
      }
    }

    // we will reload upon request
    mustreload ||= force;

    // no reload: we will return local config (might be invalid)
    if (!mustreload) return config;

    let result = await this.api.getAppConfig()
    if (result["status"] != "ok") {
      // return (more specific) remote error instead of local error 
      if (config["status"] != "ok") return result
      // otherwise return last valid config
      return config
    }

    // if remote config was invalid, return local config (might be invalid too)
    let updatedconfig = this.validateConfig(result)
    if (updatedconfig["status"] != "ok") return config

    updatedconfig["_fetchedat"] = Date.now()

    await this.storageService.set('appconfig',updatedconfig);
    return updatedconfig;    
  }

  public async getAppConfig(force: boolean = false) {
    let config = await this.internalGetAppConfig(force)

    if (config["result"] == "ok") {
      this.eventsService.publishAppConfigEvent(config["appconfig"]);
    }

    return config
  }

  private getConfigValue(data: any,key: string, defvalue: number): number {
    if (data[key] == null) return defvalue
    let v = parseInt(data[key])
    if (Number.isNaN(v)) return defvalue
    return v
  }

  public updateConfig(data) {
    this.MAX_ITEMS = this.getConfigValue(data,"max_items",DEFAULT_MAX_ITEMS)    

    if ((data["maxage_per_endpoint"] != null) && (data["maxage_per_endpoint"] instanceof Object)) {
      this.MAXAGE_PER_ENDPOINT = data["maxage_per_endpoint"]
    }
  }





}
